import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'gatsby'
import Layout from '../components/Layout'

export const SitemapPageTemplate = ({ title}) => {

  return (
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
                {title}
              </h2>
                <section>
                    <ul>
                        <li>
                            <Link to="/" className="navbar-item">
                                Home
                            </Link>
                        </li>
                        <li>
                            <Link className="navbar-item" to="/weightloss">
                                Weight loss
                            </Link>
                        </li>
                        <li>
                            <Link className="navbar-item" to="/quit-smoking">
                                Quit Smoking
                            </Link>
                        </li>
                        <li>
                            <Link className="navbar-item" to="/stress-management">
                                Stress Management
                            </Link>
                        </li>
                        <li>
                            <Link className="navbar-item" to="/unwanted-habits">
                                Unwanted Habits
                            </Link>
                        </li>
                        <li>
                            <Link className="navbar-item" to="/your-success">
                                Your success
                            </Link>
                        </li>
                        <li>
                            <Link className="navbar-item" to="/about">
                                About
                            </Link>
                        </li>
                        <li>
                            <Link className="navbar-item" to="/contact">
                                Contact
                            </Link>
                        </li>
                        <li>
                            <Link className="navbar-item" to="/privacy-policy">
                                Privacy Policy
                            </Link>
                        </li>
                        <li>
                            <Link className="navbar-item" to="/sitemap">
                                Sitemap
                            </Link>
                        </li>
                        {/*<li>
                      <a
                        className="navbar-item"
                        href="/admin/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Admin
                      </a>
                    </li>*/}
                    </ul>
                </section>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

SitemapPageTemplate.propTypes = {
  title: PropTypes.string.isRequired
}

const SitemapPage = () => {

  return (
    <Layout>
      <SitemapPageTemplate
        title="Sitemap"
      />
    </Layout>
  )
}

export default SitemapPage